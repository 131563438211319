<template>
    <div class="container-xxl flex-grow-1 container-p-y">
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Group',
    components: {
    }
}
</script>
